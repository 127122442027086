// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SocialShareModalUserQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type SocialShareModalUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    coach?: {
      __typename?: 'Coach';
      id: string;
      slug: string;
      categoryInformationList?: Array<{
        __typename?: 'CategoryInformation';
        id: string;
        category: { __typename?: 'Category'; id: string; name: string };
      }> | null;
    } | null;
  } | null;
};

export const SocialShareModalUserDocument = gql`
  query SocialShareModal_User {
    user {
      id
      coach {
        id
        slug
        categoryInformationList {
          id
          category {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useSocialShareModalUserQuery__
 *
 * To run a query within a React component, call `useSocialShareModalUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialShareModalUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialShareModalUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useSocialShareModalUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SocialShareModalUserQuery,
    SocialShareModalUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SocialShareModalUserQuery,
    SocialShareModalUserQueryVariables
  >(SocialShareModalUserDocument, options);
}
export function useSocialShareModalUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SocialShareModalUserQuery,
    SocialShareModalUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SocialShareModalUserQuery,
    SocialShareModalUserQueryVariables
  >(SocialShareModalUserDocument, options);
}
export function useSocialShareModalUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SocialShareModalUserQuery,
    SocialShareModalUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SocialShareModalUserQuery,
    SocialShareModalUserQueryVariables
  >(SocialShareModalUserDocument, options);
}
export type SocialShareModalUserQueryHookResult = ReturnType<
  typeof useSocialShareModalUserQuery
>;
export type SocialShareModalUserLazyQueryHookResult = ReturnType<
  typeof useSocialShareModalUserLazyQuery
>;
export type SocialShareModalUserSuspenseQueryHookResult = ReturnType<
  typeof useSocialShareModalUserSuspenseQuery
>;
export type SocialShareModalUserQueryResult = Apollo.QueryResult<
  SocialShareModalUserQuery,
  SocialShareModalUserQueryVariables
>;
