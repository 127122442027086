import {
  BrandFacebookSilhouette,
  BrandLinkedinSilhouette,
  BrandTwitterSilhouette,
  Button,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
  IconCheck,
  IconCopy,
  Modal,
  ModalContent,
  ModalSize,
} from '@leland-dev/leland-ui-library';
import { type FC, useCallback, useState } from 'react';

import { copyToClipboard } from '../../utils/common';

interface ShareModalProps {
  title: string;
  sharedLink: string;
  message: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ShareModal: FC<ShareModalProps> = ({
  message,
  open,
  setOpen,
  sharedLink,
  title,
}) => {
  const [isCopySuccess, setIsCopySuccess] = useState(false);

  const onCopyToClipboard = useCallback(async () => {
    await copyToClipboard(message);
    setIsCopySuccess(true);
    setTimeout(() => setIsCopySuccess(false), 2500);
  }, [message]);

  const handleOpenLink = (link: string) => {
    window.open(
      link,
      'socialshare',
      'menubar=1,resizable=1,width=500,height=500',
    );
  };

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalContent size={ModalSize.SMALL}>
        <div className="flex flex-col p-6">
          <h2 className="text-center text-2xl font-medium">{title}</h2>
          <h3 className="mt-3 text-center text-leland-gray-light">
            Post on your social channels to build brand awareness and drive
            potential customers your way.
          </h3>
          <p className="mt-6 w-full whitespace-pre-wrap rounded-lg bg-leland-gray-hover p-4">
            {message}
          </p>
          <button
            className={`${
              isCopySuccess ? '' : 'link'
            } link--primary mt-4 flex items-center space-x-2 py-2`}
            onClick={onCopyToClipboard}
            disabled={isCopySuccess}
          >
            {isCopySuccess ? (
              <IconCheck className="size-4" />
            ) : (
              <IconCopy className="size-4" />
            )}
            <span className="text-sm font-medium">
              {isCopySuccess ? 'Message copied!' : 'Copy message'}
            </span>
          </button>
          <div className="mt-5 flex w-full flex-col items-center justify-center gap-4 sm:flex-row">
            <Button
              buttonColor={ButtonColor.WHITE}
              size={ButtonSize.LARGE}
              label="LinkedIn"
              LeftIcon={BrandLinkedinSilhouette}
              width={ButtonWidth.FULL}
              onClick={() =>
                handleOpenLink(
                  `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                    sharedLink,
                  )}`,
                )
              }
            />
            <Button
              onClick={() =>
                handleOpenLink(
                  `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    sharedLink,
                  )}&title=${encodeURIComponent(message + '\n')}`,
                )
              }
              width={ButtonWidth.FULL}
              buttonColor={ButtonColor.WHITE}
              size={ButtonSize.LARGE}
              label="Twitter"
              LeftIcon={BrandTwitterSilhouette}
            />
            <Button
              buttonColor={ButtonColor.WHITE}
              size={ButtonSize.LARGE}
              label="Facebook"
              LeftIcon={BrandFacebookSilhouette}
              width={ButtonWidth.FULL}
              onClick={() =>
                handleOpenLink(
                  `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
                    sharedLink,
                  )}`,
                )
              }
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
