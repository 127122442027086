import {
  type ModalHook,
  useControlledModalState,
} from '@leland-dev/leland-ui-library';
import React, { type FC, useCallback } from 'react';

import { formatList } from '../../utils/formatters';
import { getCoachProfileUrl, getUrlString } from '../../utils/url';

import { ShareModal } from './ShareModal';
import { useSocialShareModalUserQuery } from './__generated-gql-types__/SocialShareModal.generated';

/**
 * NOTE: share urls sourced from https://github.com/bradvin/social-share-urls
 */

interface SocialShareModalHookProps {
  onClose?: () => void;
}

interface SocialShareModalProps extends SocialShareModalHookProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const SocialShareModalContent: FC<SocialShareModalProps> = ({
  onClose,
  open,
  setOpen: setOpenProp,
}) => {
  const { data } = useSocialShareModalUserQuery();
  const coachCategoriesText = formatList(
    (data?.user?.coach?.categoryInformationList ?? []).map(
      ({ category }) => category.name,
    ),
  );

  const setOpen = useCallback(
    (open: boolean) => {
      setOpenProp(open);
      if (!open && onClose) onClose();
    },
    [onClose, setOpenProp],
  );

  const message = `Very excited to share that I’m coaching on Leland!
  
I’m tremendously grateful for the mentors I’ve had in my own professional journey and want to continue paying it forward. I’d love to help you or anyone you know with ${coachCategoriesText}.
  
Let’s meet and discuss your goals. Check out my profile to learn more.
  
Excited to chat!`;

  const coachProfileUrl = getUrlString(
    getCoachProfileUrl(data?.user?.coach?.slug ?? '', null),
    { utm_source: 'social' },
  );

  return (
    <ShareModal
      open={open}
      setOpen={setOpen}
      title="Post on social and 10x your leads"
      message={message}
      sharedLink={coachProfileUrl}
    />
  );
};

export const useSocialShareModal: ModalHook<SocialShareModalHookProps> = () => {
  const [open, setOpen] = useControlledModalState();
  const SocialShareModalComponent: FC<SocialShareModalHookProps> = (props) => {
    return open ? (
      <SocialShareModalContent {...props} open={open} setOpen={setOpen} />
    ) : null;
  };
  return { open, setOpen, Modal: SocialShareModalComponent };
};
